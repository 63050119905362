.subscription-container {
  position: relative;
  padding: 20px 20px 20px 40px;
  border-radius: 15px;
  max-width: 400px;
  padding-left: 25px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
  margin-left: auto ;
  margin-right: auto ;
  margin-top: 10px;
}
.sales-notification-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.sales-notification {
  display: flex;
  align-items: center;
  background-color: #FDE7E7;
  color: #000 !important;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 10px; */
  max-width: 350px; /* Restrict the width on desktop */
}

.sales-notification img {
  width: 80px;
  height: 100px;
  margin-right: 10px;
}

.notification-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
}

.billing-points{
  font-size: 20px;
  font-weight: bold;
}
.billinglogo{
  width: 170px;
  margin-left: auto;
  margin-right: auto;
}
.subpage-head{
  padding: 8px;
  background-color: #ECF0F1;
  border-radius: 5px;
  font-size: 21px;
  font-weight: bold;
  color: #022335;
  margin-bottom: 12px;
}
.billing-head{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 25px !important;
}
.vertical-line {
  position: absolute;
  left: 20px;
  top: 20px;
  bottom: 20px;
  width: 6px;
  background-color: #19a25d;
  border-radius: 4px;
}

.subscription-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.subscription-item:last-child {
  margin-bottom: 0;
}

.icon-wrapper {
  background-color: #343a40;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.icon {
  color: #19a25d;
  font-size: 18px;
}

.icon-wrapper:last-of-type .icon {
  color: #8e7fe8;
}

.subscription-text {
  flex: 1;
  text-align: left;
  padding: 12px;
}

.main-text {
  font-size: 16px;
  margin: 0;
  color: #000  !important;
}

.sub-text {
  font-size: 14px;
  color: #6b6d76;
  margin: 0;
}


.reviews-count{
  font-size: 12px;
}

.free-sticker{
  background-color: #229954;
  color: #fff;
  padding: 2px;
  border-radius: 5px;
  font-size: 11px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 4px;
}
.ldp-ma{
width: 100%;
height: 25%;
margin-left: 0px !important;
margin-right: 0px !important;
}
.backgg{
  background-color: #022335 !important;
  border-radius: 4px; 
}

.subpage-contact{
  text-align: center !important;
  padding: 15px !important;
  background-color: #FEE3DD;
  margin-top: 25px;;
}
.subpage-gaurente{
  text-align: center !important;
  padding: 15px !important;
  /* background-color: #F1E2A2; */
  margin-top: 8px;
  font-size: 11px;
  font-weight: bold;
}
.home-page-header{
  background-color: #f8f8f8  ;
  padding: 15px;
  text-align: center;
}
a{
  color: #022335 !important;
}
.step-descrip{
  padding: 6px;
  font-weight: bold !important;
}
.box-timer{
  text-align: center !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background-color: #022335;
  color: #fff !important;
  margin-bottom: 9px !important;
  border-radius: 8px;
}
.freeshipping{
  text-align: center !important;
  font-weight: bold !important;
}
.otp-handler{
  border: 1px solid #333;
  padding: 12px !important;
  padding-bottom: 42px !important;
}
.couponbutton{
  margin-top:5px !important
}
.ldp-desc{
  margin-right: -28px !important;
  padding: 6px !important;
}
.ldg-vari{
   padding-left: 0.2em !important;
  padding-right: 0.0em !important;
  text-align: center !important;
  margin-right: -28px !important;
}
.ldp-footer{
  background-color: #022335 !important;
  color:#fff;
  text-align: center !important;
  margin-right: auto !important;
  padding: 12px !important;
}
.ldp-variant{
  margin-bottom: 8px  !important;
  font-size: 14px  !important;
  padding-left: 3px Im !important;
  background-color: #229954 !important;
  width: 100% !important;
}
.ldp-variant:focus{
  margin-bottom: 8px  !important;
  font-size: 14px  !important;
  padding-left: 3px Im !important;
  background-color: #fff !important;
  color: #229954 !important;
  width: 100% !important;
}
.ldp-variant:hover{
  margin-bottom: 8px  !important;
  font-size: 14px  !important;
  padding-left: 3px Im !important;
  background-color: #fff !important;
  color: #229954 !important;
  width: 100% !important;
}
.ldp-variant2{
  margin-bottom: 8px  !important;
  font-size: 14px  !important;
  padding-left: 3px Im !important;
  background-color: #229954 !important;
  width: 80% !important;
  margin-left: auto !important;
    margin-right: auto !important;
}

.placeorder-bt{
  width: 100% !important;
  text-align: center !important;
  font-size: 15px !important;

}
.ldp-variant:hover {
  background-color: #b6c3dc !important;
  color: #fff !important;
}
.box-buy{
  text-align: center !important;
  width: 300px !important;
  font-size: 18px !important;
  margin-top: 14px !important;
}
.ldp-fs{
  margin-top: 6% !important;
  margin-bottom: 15px !important;
  text-align: center;
  font-size: large;
  color: #000 !important;
  font-weight: bold;
}
.ldp-mh{
  font-size: 27px !important;
  text-align: center;
  padding-top: 18px !important;
}
.ldp-sh{
  padding-top: 18px !important;
  text-align: center;
}
.blog-title{
  font-size: 22px !important;
}
.blog-text{
  font-size: 18px !important
}
.plot-reader{
  margin-right: 10px !important;
}
.orderImage{
  width: 150px !important;
}
.ar-modal{
  width: 100% !important;
  max-width: 80% !important;
}
.modal-body{
  padding: 0px !important;
}

.summary-titles{
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
  color: #229954;
}

.hero-image-subs{
  width:100%;
  padding-top:25px;
}
.writer-name{
  font-size: 12px;
  margin-top: 8px !important;
  margin-bottom: 5px !important;
  text-transform: capitalize;
}

svg{
margin-bottom: 3px !important;
color:#E74C3C;

}
.numbooks{
  color:#fff;
  margin-top: 12px !important;
  background-color: #022335;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
}
.story-hero-text{
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

}
.subtagline{
  color: #022335;
  font-size: 21px;
}
.text-color{
  color: #022335 !important;
}
.story-bg-points{
  padding: 18px;
  text-align: center;
  background-color: #F1F7F3;

}
.review-name {
  font-weight: bolder !important;
  font-size: '16px';
}
.review-text{
  padding: 10px;
}
.home-sec33{
  margin-top: 20px;
  margin-bottom: 30px;
text-align: center;

}
.goals-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.overflow-hidden {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}
.round-button {
  border-radius: 50%; /* Makes the button round */
  width: 40px;  /* Set a fixed width */
  height: 40px; /* Set a fixed height */
  padding: 6px;  /* Remove padding */
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  margin-left: 6px;
  margin-right: 6px;
  color: #fff;
}

.small-tab-btn {
  min-width: 190px;  
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  background-color: #000;
  scroll-snap-align: start;
  flex: none; 

}
.hero-text-33{
  font-size: 22px !important;
  font-weight: bold;
  color:#022335;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hero-text{
  font-size: 28px !important;
  font-weight: bold;
  color:#000;
}
.hero-text2{
  font-size: 25px !important;
  font-weight: bold;
 
  text-align:center;
}
.story-action-section{
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center  !important;
  margin-bottom: 20px;
}
.story-status{
  font-size: 8px;
  display: inline-block;
  border-radius: 16px;
  border: solid 1px #ddd6cf;
  text-transform: uppercase;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0px 10px;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
  color: #229954;
  background-color: #EEEBED;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}
.green-text{
  color: #1C8427 !important;
}

.storiestatslabel{
  font-size: 15px;
  text-align: center;
}

.storychaptersection{
  padding-top: 16px;
  padding-bottom: 16px;
  padding: 14px;
  background-color: #fff !important;
}
.profile-backgrd{
  padding: 15px;
  background-color: #229954;
  color:#fff !important;
  font-size: 18px;
  text-transform: capitalize;
}
.my-facebook-button-class{
  font-size: 14px !important;
  width: 250px;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important;
  text-align: left;
  padding: 11px;
  color: #fff !important;
  background-color: #229954 !important;
}
.why-bookscart{
  text-align: center  !important;
  padding-top: 20px !important;
}
.social-logins{
  width: 250px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-block{
  margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.fbicontest{
  padding-right: 25px !important;
}
.follow-profile-image{
  width: 150px;
  height: 150px;
  border-radius: 20px;
}
.my-google-button-class{
  font-size: 14px !important;
  width: 250px;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important;
  color: #fff !important;
  background-color: #229954 !important;
}
.commentbutton{
  float: right !important;
}
.episodereadheader{
  text-align: center !important;
  padding-bottom: 17px;
}
.storycomments{
  padding: 25px;
  border-bottom: 1px solid #999;
}
.episodecontent{
  padding: 25px !important;
  white-space: pre-wrap !important;
  font-size: 17px;
  font-weight: 800;
  
 
      box-shadow: 0 10px 10px rgba(20, 28, 49, .03), 0 2px 6px rgba(0, 0, 0, .03);
      border-radius: 14px;
      border: 2px solid #eef1f4;
 

      
    -moz-user-select:none;
   -webkit-user-select: none;
   -ms-user-select: none; 
   user-select: none;
}
.summary-reading{
    -moz-user-select:none;
   -webkit-user-select: none;
   -ms-user-select: none; 
   user-select: none;
}
.newbuttons{
  padding: 0.6rem 1.0rem !important;
  cursor: pointer;
  background-color: #229954;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
  margin-left: 6px;
  margin-right: 6px;
  box-shadow: 1px 2px #e6dcba;
}
.wd200{
  width: 200px !important;
}
.story-tags{
  padding: 10px;
  padding-left: 8px;
}
.chaptersection{
  padding-bottom: 8px;
  border-bottom: 1px solid #CEE5D8;
}
.home-secff{
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  padding: 35px;
  font-size: 18px;
  color: #F1C40F;
}
.home-secgg{
  padding: 30px !important;
  padding-left: 35px !important;
  font-size: 16px;;
}
.chaptersection:hover{
  padding-bottom: 8px;
  border-bottom: 1px solid #022335;
  background-color: #C5DCE8;
}
.storydescriptionlist{
  max-height: 40px;
  overflow: hidden;
  font-size: 13px !important;
  padding-right: 5px;
}
.storystatslist{
  font-size: 15px;
    vertical-align: bottom!important;
    padding: 3px;
}
.storietaglabel{
  font-size: 18px;
  margin-bottom: 20px !important;
}
.storiestatsvalue{
  font-size: 14px;
  text-align: center;
}
.storystatsrow{
  padding-top: 10px;
  text-transform: capitalize  !important;
  
}
.storydescription{
  color: #000;
  font-size: 18px;
  line-height: 22px;
  white-space: pre-wrap !important;
  padding: '10%';
}
.new-customer{
  padding-top: 10px;
  float: right;
}
.red-text{
  color: #E74C3C !important;
  margin-bottom: 6px;
}
.grey-text{
  color: #ABB2B9;
}
.text-center-rating{
  text-align: left;
}
.storypagecover{
  width: 190px !important;
  height: 320px !important;
  float: right !important;
  border-radius: 10px !important;
 
}
.chapter-title{
  font-weight: 500 !important;
  text-align: left;
  font-size: 15px !important;
  color:#022335 !important
}
.book-author{
  font-size: 11px !important;
  text-transform: capitalize !important;
}
.story-head-section{
  padding-top: 10px ;

}
.margin-left{
  margin-left: 0.0em !important;
  width: 100% !important;
}
.form-check-input{
  margin: 1px;
}
.form-check-label{
  font-size: 14px;
}
.float-right{
  float:right
}
.pre-prod{
  width: 100% !important;
  font-size: 19px;
  word-wrap: break-word !important;
}
.list-group-item{
  padding: 0.1rem 0.1rem !important  ;
}
main {
  min-height: 80vh;
}
h3 {
  padding: 1rem 0;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem;
}
h1 {
  font-size: 1.0rem !important;
  margin-bottom: 0rem

}
.numbooks{
  font-size: 28px;
  font-weight: bold;
  margin-top: 8px;
}
.kcalci{
  width: 40%;
  background-color: #EA877D;
  color: #fff;
  margin-left: 8px;
}
.kcalci:hover {
  background-color: #022335 !important;
  color: #fff !important;
}
.rating span {
  margin: 0.1rem;
  color: #229954 !important;
}
.productPrice {
  color: #229954;
  font-size: 14px;
  font-weight: bold;
}
.logoutSection{
  margin-top: 10%;
  text-align: center;
}
.cart-productPrice {
  color: #229954;
  font-size: large;
  font-weight: bold;
}
.subprofile{
  margin-top: 20px;
}
.product-details {
  text-transform: capitalize;
  padding: 5px;
}
.search-bar-lp{
  width: 50%;
  margin-left: auto !important;
  margin-right: auto !important;
}
.siteLogo {
  width: 200px;
}
.siteLogo-main{
  width: 360px;

}

.siteLogo2 {
  width: 200px;
}
.profile-icons{
  width: 25px !important;
  height: 25px !important;
  margin-left: 15px;
  margin-top: 40px;
}
.siteLogo-app{
  
  width: 200px;
}

.home-page-tagline{
  margin-top: 5px;
  text-align: center;
  padding-top: 8px;
  background-color: #C9CACD;
  color: #000;
  padding-bottom: 8px;
  font-size: 19px;
  text-transform: uppercase;
  cursor: auto;
}
a {
  text-decoration: none;
}
.stickyProgress {
  position: fixed;
  display: block;
  top: 0;
}
img {
  margin-top: 5px !important;
}
.marginbottom {
  margin-bottom: 10px;
}
.nav{
  flex-wrap: inherit !important;
}
.navbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  color: #229954;
}
.navbar-light .navbar-nav .nav-link {
  color: #229954;
}
.dropdown-item {
  color: #229954 !important;
}
.card-body {
  padding: 0.3rem 0.3rem;
}
.card {
  border: 0px;
}
main {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  text-transform: capitalize !important;
  letter-spacing: 1px;
  color:#022335 ;
  font-size: 16px;
  margin-bottom: 6px !important;
  margin-top: 6px !important;
}
.h6 {
  letter-spacing: 0.4px !important;
  
}

.card-title {
  margin-bottom: 0;
  font-size: 16px;
}
.card-subtitle {
  text-transform: lowercase !important;
}
.btn-primary:hover {
  background-color: #022335;
  color: #fff;
}
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #022335;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif !important;

}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.4;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #229954;
  color: #ffffff !important;
}
.btn{
  font-weight: 500;
  border-radius: 21px;
  text-transform: capitalize  !important;
}
body {
  
  color: #000 !important;
  background-color: #fff  !important;
  
  overflow-x: hidden !important;

}

.green-button {
  background-color: #229954;
  color: #ffffff;
  float: right !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #229954;
  border-radius: 5px;
}
.product-description {
  /* overflow: scroll;
  overflow-x: hidden;
  height: 200px;
  min-height: 10px !important; */
}
.read-or-hide {
  text-align: right !important;
  text-decoration: underline;
  font-size: small;
}
/* width */
*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #022335;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #e6dcba;
}
.carousel-container {
  border-bottom: 1px double #999;
  padding-bottom: 4px;
}
.lang-block {
  text-align: center;
}
.hproducts {
  padding: 3px;
}
.list-group-item {
  border: 0px;
  background-color: inherit !important;
}
.product-title {
  margin-bottom: 0px !important;
  margin-top: 5px !important;
  font-size: medium  !important;
}
strong {
  font-weight: 550;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.2rem 0.5rem;
  font-size: 9px;
}
.row > * {
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.ebook-mention {
  padding-top: 0px !important;
  color: #229954;
}
.registerErrorMessage{
  font-weight: bold;
  color: #ff4231;
}
.product-quality {
  margin-top: 10px;
  padding-top: 8px;
  font-size: 14px !important;
  padding-left: 6px;
  padding-right: 6px;
  border-bottom: 1px solid #c9c7c0;
  margin-left: 10px;
  margin-right: 10px;
}
.themecolor {
  color: #022335 !important;
}
.themecolor2 {
  color: #022335 !important;
}
.product-image {
  width: 220px;
  /* height: 80% !important; */
  max-width: 60%;
  margin-top: 3px !important;
  box-shadow: 3px 7px 5px #888888;
  border-radius: 12px;
}
h3 {
  padding: 0px;
}
.footer-social {
  padding-right: 10px;
}
.footer-payments {
  width: 80%;
  margin-bottom: 10px;
}
.cart-nav {
  flex-wrap: nowrap !important;
  margin-bottom: 0px !important;
}
.cart-header {
  text-align: center;
}
.cart-form {
  padding-left: 8px;
  padding-right: 8px;
}
.cart-form-password {
  padding: 25px;
  padding-bottom: 35px;
}
.float-right {
  float: right !important;
}
.form-control {
  border: 1px #b6c3dc solid !important;
  margin-bottom: 8px;
  padding: 0.75rem 0.8rem;
  color: #000 !important;
  background-color: #fff !important;
}
.green {
  color: #229954 !important;
}
video {
  width: 100%;
  max-height: 100%;
}
.page-item.active .page-link {
  background-color: #022335 !important;
}
.order-summary {
  text-align: left;
  border: 1px #999 solid;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  padding-left: 15px !important;
  box-shadow: 1px 2px #022335;
  font-size: 12px !important;
  font-weight: bold;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 25px !important;
}

.pagination li {
  display: block;
}
.page-link {
  padding: 0.375rem 0.35rem;
  font-size: 12px !important;
}
.h4,
h4 {
  font-size: 1rem;
}
#the_iframe {
  margin-left: 8px;
  margin-left: 8px;
}
a:hover {
  color: #022335 !important;
}
a:active {
  color: #022335 !important;
}
.capitalize-text {
  text-transform: capitalize !important;
}
.bg-idanger {
  background-color: #229954;
}
.very-small-text{
  font-size: 12px !important;
}
.progress {
  z-index: 1000;
  background-color: #B2BABB;
  height: 18px !important;
  border-radius: 4px;
}
.cart-form {
  margin-bottom: 50px !important;
}
.progress-bar {
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
}
.bg-dark {
  --bs-bg-opacity: 0 !important;
}
.hero-text {
  padding: auto;
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
  font-weight: 700;
}
.center-link {
  text-align: center !important;
}
.bg-light {
  border: 0px !important;
}
.reviewImage{
  width: 100%;
  padding: 8px;
}
ol{
  padding-left: 0em !important;
}
.popularity-text {
  color: #229954;
  font-size: 13px;
  background-color: inherit !important;
}
.search-results{
  text-align: center  !important;
}
.instock {
  padding-top: 1px;
  color: red;
}

.largefontreading{
  font-size: 22px !important;
}
.smallfontreading{
  font-size: 13px !important;
}
.footer-menu {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  padding: 10px 0;
  text-align: center;
  margin-left: 0px !important;
  font-size: 17px;
  color: #2a2b2e;
  
}
.footer-menu-list{
  display: inline-flex !important;
  list-style: none;
  width: 100%;
  
  
}
.footer-menu-list-item{
  width: 33% !important;
  border-right: 1px solid #777;
}
@media only screen and (max-width: 767px) {
  .footer-menu {
    display: inline-flex !important;
  }
}
.alert-info {
  color: #ffffff;
  background-color: #b6c3dc;
  /* border-color: #135d7c; */
}
.review-tile{
  font-weight: bold;
  text-transform: capitalize;
}
a:hover{
  color: #022335 !important;
}
.storylistrow{
  max-height: 247px ;
  border-radius: 6px;
  overflow: auto;
  overflow-y: hidden;
  margin-top: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Horizontal offset, vertical offset, blur radius, and color */
   transition: box-shadow 0.2s ease-in-out;
   padding: 1px !important;
   margin-right: 0px !important;
   width: 99%;
   margin-left: 4px !important;
}
.storylistrow:hover{
  box-shadow: 0 8px 16px rgba(0,0,0,0.5);
}
.story-cover-img{
  margin-bottom: 8px !important;
}
.siteLogo-new{
  width: 200px;
  margin-left: 15%;
}
.subbutton-signup{
  margin-bottom: 8px !important;
  margin-top: 20px !important;
  background-color: #022335 !important;
  float: right !important;
  margin-right: 25px;
}
.hero-sec1{
 width: 500px;
 text-align: center;
 margin-left: auto  ;
  margin-right: auto;
  overflow-x: hidden;

}
.hero-text1{
  line-height:28px;
    color: #022335;
    margin-top: 65px;

}
.hero-text2{
  font-size: 42px !important;
  font-weight: bold;
  color: #022335;
  padding: '12px';
}
.hero-text3{
  font-size: 16px !important;
  text-align: left !important;
  color: #6b6d76;
  padding: 28px;
  font-weight: 900;
  
}
.ben-img{
  width: 80px;
}

.siteLogo-regis{
  width: 200px;
}
/* Mobiles only css */
@media screen and (max-width: 740px) {
  .sales-notification-container {
    right: 0;
    left: 0;
    padding: 10px;
  }

  .sales-notification {
    max-width: none;
  }

  .sales-notification img {
    width: 60px;
    height: 75px;
    margin-right: 10px;
  }
  .hero-image2{
  width: 100%;
  }
  .hero-text3{
    font-size: 15px !important;
    text-align: left !important;
    color: #6b6d76;
    padding: 28px;
  }
  .hero-text2{
    font-size: 34px !important;
    font-weight: bold;
    color: #022335;
  }
  .hero-text1{
    line-height:24px;
    color: #022335;
    margin-top: 45px;
  }
  .hero-sec1{
width: 100%;
  }
  .siteLogo-new{
    width: 175px;
  }
  .subbutton-signup{
    margin-bottom: 8px !important;
    margin-top: 23px !important;
    background-color: #022335 !important;
    float: right !important;
    margin-right: 25px;
  }
  .search-bar-lp{
    width: 100%;
  }
  .storydescriptionlist{
    max-height: 40px;
  }
  .storylistrow{
    max-height: 188px ;
    overflow: auto;
    overflow-y: hidden;
    margin-bottom: 8px;;
  }
  .card-title {
    font-size: 0.8em !important;
    padding-left: 0px;
    padding-right: 0px;
  }
  .card-subtitle {
    font-size: 0.7em !important;
  }
}
#header {
  position: absolute;
  width: 220px;
}
#header .pro-sidebar {
  height: 90vh;
}
#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
.header-top {
  background-color: #229954;
  color: #ffffff !important;
  text-align: center;
  padding: 4px;
  width: 100%;
}
.header-link {
  color: #fff !important;
}
.header-link:hover {
  color: #fff !important;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}
#header .logo {
  padding: 20px;
}
.pro-sidebar .pro-menu a {
  color: #000000 !important;
}
#boxTypeSelect {
  width: 50% !important;
}
.mobile-view {
  display: none !important;
}

.desktop-view {
  display: block !important;
}
.add-to-wishlist {
  text-align: left !important;
  margin-top: 4px !important;
}
.wishlist {
  display: none;
}
.product-grid-size {
  width: 12% !important;
}
.login-box{
  background-color: #fff;
  margin: auto;
  max-width: 700px;
}
.text-muted {
  color: #022335;
}
.login-register{
  padding: 10px;
  border: 1px solid #229954;
  margin-left: auto;
  margin-right: auto;
  background-color: #b6c3dc;
}
.checkout-shipping-address {
  padding: 20px;
  color: #229954;
  text-transform: capitalize;
  padding-bottom: 0.5px;
  margin-bottom: 8px;
  font-size: 14px !important;
  border-bottom: 0.1em solid#229954;
}
@media only screen and (max-width: 944px) {
  .product-grid-size {
    width: 20% !important;
  }
}
@media only screen and (max-width: 1040px) {
  .product-grid-size {
    width: 15% !important;
  }
}

.screen-back-button {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #229954;
}
.font-control {
  padding-top: 5px;
  padding-bottom: 5px;
}
html{
  overflow-x: hidden !important;
}
.delivery-date {
  color: #229954 !important;
  font-size: 12px;
  font-weight: bold;
}
.mobile-center {
  background-color: inherit !important;
}
.audioplaybutton{
  border-radius: 75%;
  background-color: #E74C3C;
  color: #fff;
  padding: 10px;
  margin-top: 8px;
}
.bookscartbox-section{
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 0px !important;
}
.book-title{
  max-height: 46px;
  overflow: hidden;
}
.title-main{
  text-transform: capitalize !important;

}

.onb-form{
  margin: 10px !important;
  text-align: center !important;
  margin-left: 22px !important;

}
.onb-label{
  font-weight: bold !important;
  color: #022335 !important;
}
.betty-btn{
 border: 1px solid #999 !important;
 width: 100% !important;
 border-radius: 12px  !important;
 margin-top: 6px !important;
 margin-left: auto !important;

 margin-right: auto !important;
}
.subbuttonnx{
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  font-size: 15px;
  font-weight: bold;
  background-color: #229954 !important;
}
.subbuttonnx:hover{
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  background-color: #022335 !important;
  color: #fff !important;
}
.subbutton{
  margin-bottom: 8px !important;
  margin-top: 20px !important;
  background-color: #229954 !important;
}
.subbutton:hover{
  margin-bottom: 8px !important;
  margin-top: 20px !important;
  background-color: #022335 !important;
  color: #fff !important;
}
.new-books{
  font-weight: bold !important;
  color: #1C8427 !important;
  text-transform: capitalize !important;
  margin-top: 3px;
  text-align: left !important;
}
.subButton:hover{
  background-color: #C5DCE8 !important;
  color: #000 !important;
 }
/* Mobile view */
@media only screen and (max-width: 720px) {
  .bgs-black{
    background-color: #2a2b2e !important;
    color: #fff !important;
    height: 100% !important;
  }
  .story-hero-text{
    margin-top: 50px;
    margin-bottom: 50px;
    
  }
  .hero-text{
    font-size: 20px !important;
  }
  .ar-modal{
    width: 99% !important;
    max-width: 95% !important;
  }
  .table td, .table th {
    padding: 0.8rem !important;
}
  .storypagecover{
    width: 112px !important;
    height: 160px !important;
    float: right !important;
    border-radius: 10px !important;
   
  }
  .book-title{
    overflow: hidden;
    max-height: 41px;

  }
  .login-box{
    background-color: #fff;
    margin: auto;
    width: 99%;
  }
  .ldp-ma{
    background-image: none;
    width: 100%;
    height: 25%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    }
  .mobile-center {
    float: center !important;
    text-align: center !important;
    margin-bottom: 5px;
    background-color: inherit !important;
  }
  .readnow-button {
    float: center !important;
    margin-left: 30%;
  }
  .navlink {
    display: none !important;
  }
  .checkout-shipping-address {
    font-size: 13px;
  }
  .product-grid-size {
    width: 33% !important;
  }
  .navbar-toggler {
    border: 0.1em solid #b6c3dc !important;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 0.1em solid #b6c3dc;
  }
  .lang-img {
    /* width: 100px !important; */
    padding-right: 20px;
  }
  .navbar-brand {
    margin-right: 0px !important;
  }
  .text-center-product {
    text-align: center !important;
  }
  
  .wishlist {
    display: block;
    color: #229954 !important;
    font-size: 18px;
    text-align: right;
    margin-left: auto;
  }
  .themeColor {
    color: #229954 !important;
  }
  .themecolorlight {
    color: #b6c3dc !important;
  }
  .product-image {
    width: 140px;
    border-radius: 12px;
  }
 
.addtocart{
  float: left !important;
}
  .add-to-wishlist {
    text-align: left !important;
  }
  .mobile-view {
    display: block !important;
  }
  .book-image{
    padding: 4px !important;
  }
  .desktop-view {
    display: none !important;
  }
  #boxTypeSelect {
    width: 100% !important;
  }
  .onlyMobile {
    display: block !important;
  }
  .onlyDesktop {
    display: none !important;
  }
  .search-button-mobile {
    padding: 12px !important;
    background-color: #022335 !important;
  }
  .nearmebookstore {
    background-color: #C9CACD;
    font-size: 15px;
    padding-bottom: 3px !important;
    margin-top: 3px;
  }
  .secondhand-bookstore{
    font-size: 11px !important;
  }
  
 .text-center-mobile{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
 }
 
 
  .homepage-mobile-bar-col {
    color: #229954 !important;
    padding-bottom: 0px !important;
    padding-right: 10px;
    padding-left: 10px;
  }
  .search-box-mobile {
    border: 1px solid #edede5;
    padding: 3px;
    padding-left: 22px !important;
    padding-right: 22px !important;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    margin-bottom: 4px !important;
    margin-top: 0px !important;
    background-color: #b6c3dc;
    overflow-x: hidden !important;
  }
  .siteLogo {
    width: 250px;
  }
  .siteLogo2 {
    width: 180px;
  }
  .siteLogo-app{
    width: 180px;
  }
}
.form-control:focus {
  box-shadow: 0 0 0 0rem #fece00 !important;
}
.adminBox {
  width: 200px;
  height: 200px;
  background-color: #fffde7;
  margin-right: 20px;
}
.site-footer {
  
  color: #229954 !important;
  margin-top: 10px;
}
.site-footer Col {
  color: #ffffff !important;
}
.lp-text-app{
  width: 90%;
  text-align: center;
  width: 100% !important;
  margin-top: 25px;;
}
.site-copyright {
  
  color: #022335 !important;
  width: 100% !important;
  margin-top: 25px;
}
.react-multiple-carousel__arrow {
  background-color: #229954 !important;
  font-size: small;
  min-width: 10px !important;
}
.customer-nav {
  margin-right: 10px;
  margin-left: 10px;
}
.small-fonts {
  font-size: 13px;
}
.estimated-price {
  color: #ff5733;
  font-weight: bold;
}
.bottom-border {
  border-bottom: 1px solid #e7e4db !important;
  padding-bottom: 1px;
}
.bottom-padding {
  padding-bottom: 4px;
}
.product-page-price {
  font-weight: bolder;
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 20px;
}
.book-store-near-me {
  padding-bottom: 5px;
  box-shadow: 3px 7px 5px #888888;
}
.select-category {
  padding-top: 7px;
  text-align: center;
}.episodetitle-name{
  font-size: 23px;
}
.bookfair-event-header{
  color:#000;
  padding:6px;
  border: 1px solid #999;
}
.book-image {
  /* box-shadow: 3px 7px 5px #888888; */
  border-radius: 8px;
  /* border: 0.05em solid #D0CCC7; */
  padding: 5px;
  background-color: #EEEBED;
}
.card-text {
  margin-bottom: 0px !important;
}
.print-label {
  /* display: none; */
  font-size: 11px;
  padding: 30px;
  padding-bottom: 50px;
}
.very-small {
  font-size: medium;
  color: #229954;
}
.btn-outline-success {
  color: #fff !important;
  border-color: #ffffff !important;
  background-color: #F34C19 !important;
}
.line-height{
  margin-bottom: -10px;
  margin-top:-10px;
}
.very-small-line-height {
  line-height: 15px;
  font-size: 12px !important;
  padding-top: 8px;
  
}
.padding-top-menu {
  padding-top: 0.75rem;
}
.login-image {
  padding-top: 50px;
  width: 100%;
}

#scanner-container {
  position: relative;
}

.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
.text-pointer {
  cursor: pointer;
  border: 1px solid #999;
  padding: 14px;
  box-shadow: 1px 2px #666;
}
.image-center {
  margin-left: 33%;
}
.order-row {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid#999;
}
.checkoutForm{
  padding: 0.55em !important;
  background-color: #fff !important;
}
.btn-dark {
  background-color: #229954;
  color: #fff;
}
.profile-button :hover {
  color: #ffffff !important;
}
.profile-button {
  font-size: 14px;
  display: inline-block;
  border-radius: 16px;
  border: solid 1px #ddd6cf;
  text-transform: uppercase;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 13px;
  font-weight: 700;
  height: 30px;
  line-height: 16px;
  color: #000;
  background-color: #b6c3dc;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}
.profile-card-footer {
  padding-bottom: 20px;
  padding-top: 20px;
}
.profile-tabs {
  background-color: #b6c3dc !important;
}
.alert-info {
  color: #229954;
}
/* --------------------------------------------- */
#title {
  width: 900px;
  min-height: 18px;
  margin: 10px auto;
  text-align: center;
  font-size: 16px;
  color: #e2e2e2;
  font-weight: 400;
}

#title:hover {
  color: #777;
}

#viewer.spreads {
  width: 300px !important;
  height: 400px;
  box-shadow: 0 0 4px #ccc;
  border-radius: 5px;
  padding: 0;
  position: relative;
  margin: 5px auto;
  /* background: white url('./ajax-loader.gif') center center no-repeat; */
  top: calc(50vh - 400px);
}

#viewer.spreads .epub-view > iframe {
  background: white;
}

#viewer.scrolled {
  overflow: hidden;
  /* width: 800px; */
  margin: 0 auto;
  position: relative;
  /* background: url('./ajax-loader.gif') center center no-repeat; */
  padding: 1px;
  background: white;
}

#viewer.scrolled .epub-view > iframe {
  background: white;
}

#prev {
  left: 0;
}

#next {
  right: 0;
}

#toc {
  display: block;
  margin: 5px auto;
  padding: 10px !important;
  font-size: small;
}

@media (min-width: 1000px) {
  .subbutton-signup{
    margin-right: 85px;
  }
  #viewer.spreads:after {
    position: absolute;
    width: 1px;
    border-right: 1px #000 solid;
    height: 90%;
    z-index: 1;
    left: 50%;
    margin-left: -1px;
    top: 5%;
    opacity: 0.15;
    box-shadow: -2px 0 15px rgba(0, 0, 0, 1);
    content: '';
  }

  #viewer.spreads.single:after {
    display: none;
  }

  #prev {
    left: 40px;
  }

  #next {
    right: 40px;
  }
}

.arrow {
  position: fixed;
  top: 50%;
  margin-top: -32px;
  font-size: 64px;
  color: #e2e2e2;
  font-family: arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-decoration: none;
}

.navlink {
  margin: 14px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #ccc;
}

.arrow:hover,
.navlink:hover {
  color: #777;
}

.arrow:active,
.navlink:hover {
  color: #000;
}

#book-wrapper {
  width: 480px;
  height: 640px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin: 28px auto;
  background: #fff;
  border-radius: 0 5px 5px 0;
  position: absolute;
}

#book-viewer {
  width: 480px;
  height: 660px;
  margin: -30px auto;
  -moz-box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
}

#book-viewer iframe {
  padding: 40px 40px;
}
.readingtime{
  padding-top: 10px;
  padding-bottom: 10px;
}
#controls {
  margin-top: 22px;
  position: absolute;
  bottom: 2px;
  left: 50%;
  width: 280px;
  margin-left: -160px;
  text-align: center;
  display: none;
}
.user-intrest {
  font-size: 14px;
  display: inline-block;
  border-radius: 16px;
  border: solid 1px #ddd6cf;
  margin-right: 4px;
  margin-bottom: 4px;
  margin-top: 12px;
  padding: 0px 8px;
  height: 24px;
  line-height: 24px;
  color: #229954;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}
.user-intrest:hover {
  background-color: #229954;
  color: #ffffff !important;
}
.topic {
  font-size: 10px;
  display: inline-block;
  border-radius: 16px;
  border: solid 1px #ddd6cf;
  text-transform: uppercase;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 0px 13px;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
  color: #022335;
  background-color: #C5DCE8;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}
.lp-rating-sect{
  text-align: center;
  padding: auto;
}
.close-button{
  float: right !important;
  text-align: right !important;
  margin-right: 12px !important;
  margin-top: 4px !important;
}
.searchbar{
  border-radius: 8px;
}
.topic-cat {
  font-size: 14px;
  display: inline-block;
  border-radius: 16px;
  border: solid 1px #ddd6cf;
  /* text-transform: uppercase; */
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 8px 10px;
  font-weight: 700;
  height: 40px;
  line-height: 25px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}
#controls > input[type='range'] {
  width: 180px;
}
.cate-name-list{
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
#navigation {
  width: 400px;
  height: 100vh;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  background: #777;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  -moz-transition: -moz-transform 0.25s ease-out;
  -ms-transition: -moz-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}

#navigation.fixed {
  position: fixed;
}

#navigation h1 {
  width: 200px;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  margin-bottom: 10px;
}

#navigation h2 {
  font-size: 14px;
  font-weight: normal;
  color: #b0b0b0;
  margin-bottom: 20px;
}

#navigation ul {
  padding-left: 36px;
  margin-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 340px;
}

#navigation ul li {
  list-style: decimal;
  margin-bottom: 10px;
  color: #cccddd;
  font-size: 12px;
  padding-left: 0;
  margin-left: 0;
}

#navigation ul li a {
  color: #ccc;
  text-decoration: none;
}

#navigation ul li a:hover {
  color: #fff;
  text-decoration: underline;
}

#navigation ul li a.active {
  color: #fff;
}

#navigation #cover {
  display: block;
  margin: 24px auto;
}

#navigation #closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  color: #cccddd;
  width: 24px;
}

#navigation.closed {
  -webkit-transform: translate(-400px, 0);
  -moz-transform: translate(-400px, 0);
  -ms-transform: translate(-400px, 0);
  transform: translate(-400px, 0);
}

/* svg {
  display: block;
} */

.close-x {
  stroke: #cccddd;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 5;
}

.close-x:hover {
  stroke: #fff;
}

#opener {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  stroke: #e2e2e2;
  fill: #e2e2e2;
}

#opener:hover {
  stroke: #777;
  fill: #777;
}
